/**
 * transition mixin
 * @type {map}
 */
@mixin transition-mixin($properties...) {
    $declarations: ();

    @each $declaration in $properties {
        $duration: .25s;
        $delay: false;
        $timing-function: ease;
        $property: all;

        @if type-of($declaration) == 'map' {
            $duration: if(map_get($declaration, 'duration'), #{map_get($declaration, 'duration')}, $duration);
            $delay: if(map_get($declaration, 'delay'), #{map_get($declaration, 'delay')}, $delay);
            $timing-function: if(map_get($declaration, 'timing-function'), #{map_get($declaration, 'timing-function')}, $timing-function);
            $property: if(map_get($declaration, 'property'), #{map_get($declaration, 'property')}, $property);
        } @else {
            $property: $declaration;
        }
        
        $delayValue: if($delay, ' ' + $delay, '');
        $declarations: append($declarations, #{$property + ' ' + $duration + $delayValue + ' ' + $timing-function}, comma);
    }

    transition: $declarations;
}
